<template>
  <!-- profil -->
  <div
    v-if="dialogNotEmpty"
    class="conversation-info"
    :class="chatInfo ? 'show' : ''"
  >
    <!--header-->
    <div class="header">
      <span class="close-chat-info" @click="toggleChatInfo()">×</span>
      <span class="title"> Chat &amp; Customer Info </span>
    </div>

    <!--body content-->
    <div class="body">
      <div class="conversation-info__top" style="align-items: center">
        <div
          class="conversation-info__avatar"
          style="
            background-image: url('rw-karakter.png'), url('rw-karakter.png');
          "
        ></div>
        <div class="conversation-info__user-info">
          <div class="name d-flex room-name">
            <div class="flex text-ellipsis">{{ dialogActive.name }}</div>
          </div>
          <div>{{ dialogActive.ext.ip }}</div>
          <!-- <div>addin@rumahweb.co.id</div> -->
          <div>
            <a :href="dialogActive.ext.referrer" target="_blank">{{
              dialogActive.ext.referrer
            }}</a>
          </div>
        </div>
      </div>
      <div class="conversation-info__bottom">
        <ul>
          <li>
            <div class="rw-action-box">
              <button @click="addAgentOpen = true">Add Agent</button>
              <button @click="exportChat()">Export</button>
              <button @click="cannedMessageOpen = true">Canned</button>
              <button @click="endChat()">End Chat</button>
            </div>
          </li>

          <!-- for agents -->
          <li>
            <span>Agents</span>
            <div class="conversation-bottom__participants">
              <!-- <div class="empty-state">No Agents Assigned Yet</div> -->
              <ul>
                <li>
                  <div
                    class="participants-avatar__img"
                    style="background-image: url('rw-staff.png')"
                  ></div>
                  <div class="participants-detail__info">
                    <div class="flex">addin</div>
                    <span class="text-ellipsis"
                      >akhul.syaifudin@rumahweb.co.id</span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </li>

          <!-- canned message-->
          <li v-if="cannedMessageOpen">
            <div class="additional-info-window">
              <div class="additional-info__header">
                <img
                  src="img/left-arrow-grey.svg"
                  @click="cannedMessageOpen = false"
                  alt="Back"
                />
                Canned message
              </div>
              <div
                class="additional-info__body"
                style="height: 85vh; overflow: hidden auto"
              >
                <ul style="width: 100%">
                  <li
                    v-for="(canned, key) in arr.canned"
                    :key="key"
                    class="canned-list"
                    @click="sendCanned(canned.id)"
                  >
                    <h4>{{ canned.title }}</h4>
                    <div class="text-truncate" :title="canned.message">
                      <small>{{ canned.message }}</small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <!-- tambah agents -->
          <li v-if="addAgentOpen">
            <div class="additional-info-window">
              <div class="additional-info__header">
                <img
                  src="img/left-arrow-grey.svg"
                  @click="addAgentOpen = false"
                />
                Add Agent
              </div>
              <div class="additional-info__body">
                <form action="">
                  <div class="form-group">
                    <label>Pilih Departement</label>
                    <select name="">
                      <option value="">Sales</option>
                      <option value="">Billing</option>
                      <option value="">Technical Support</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Pilih Agent</label>
                    <select name="">
                      <option value="">Tulus</option>
                      <option value="">Dhaniar</option>
                    </select>
                  </div>
                  <button>Submit</button> &nbsp;
                  <button class="btn btn-cancel" @click="addAgentOpen = false">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- end profile -->
</template>

<script>
import canned from "@/common/dummy/canned.json";
export default {
  props: {
    chatInfo: {
      required: true,
      type: Boolean,
    },
    dialogActive: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      cannedMessageOpen: false,
      addAgentOpen: false,
      arr: {
        canned: canned,
      },
    };
  },
  methods: {
    toggleChatInfo: function () {
      this.$emit("toggleChatInfo", {});
    },
    /**
     * --------------------------------------------------------------
     * export staff to .txt
     * --------------------------------------------------------------
     */
    exportChat: function () {
      this.$swal("Success", "exported", "success");
    },

    /**
     * --------------------------------------------------------------
     * proses to close conversation
     * --------------------------------------------------------------
     */
    endChat: function () {
      this.$swal({
        title: "Warning",
        html: "Are you sure want to be close this conversation ?",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.$swal("Success", "Conversation closed", "success");
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * send canned message to parent
     * --------------------------------------------------------------
     */
    sendCanned: function (id) {
      this.$emit("sendCanned", {
        type: "sendCanned",
        data: canned.find((msg) => msg.id === id),
      });
    },
  },
  computed: {
    dialogNotEmpty: function () {
      return Object.keys(this.dialogActive).length !== 0 ? true : false;
    },
  },
};
</script>

<style scoped>
.rw-action-box {
  display: flex;
  flex-wrap: wrap;
}
.rw-action-box button {
  width: 47%;
  margin: 2px;
}
.conversation-info .conversation-info__bottom ul li {
  padding: 9px;
}
/**
 * --------------------------------------------------------------
 * style canned
 * --------------------------------------------------------------
 */
.canned-list {
  cursor: pointer;
}

/**
 * --------------------------------------------------------------
 * style agent
 * --------------------------------------------------------------
 */
form {
  padding: 20px;
  width: 100%;
}
.btn.btn-cancel {
  color: #898989;
  background: transparent;
}
</style>
