<template>
  <div class="conversation-list">
    <div class="conversation-list__header">
      <span>Inbox</span>
      <div class="d-flex-center">
        <span>{{ switchOn ? "Online" : "Offline" }}</span>
        <a href="javascript:void(0);" @click="toggleSwitch()">
          <span
            class="switch"
            :class="switchOn ? 'switch--on' : ''"
            style="margin-left: 0px"
          ></span>
        </a>
      </div>
      <a href="javascript:void(0);" @click="logout()">
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="11" stroke="#FDFDFD" stroke-width="2" />
          <path
            d="M17.4286 9C18.983 10.0917 20 11.9056 20 13.9586C20 17.2952 17.3137 20 14 20C10.6863 20 8 17.2952 8 13.9586C8 11.9056 9.01701 10.0917 10.5714 9"
            stroke="#FDFDFD"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M14 7.5V13"
            stroke="#FDFDFD"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
        <div class="tooltip top-center">Logout</div>
      </a>
    </div>
    <div class="conversation-list__header search">
      <div class="d-flex">
        <!-- all -->
        <a
          href="javascript:void(0);"
          @click="getPeople('all')"
          :class="currentTab === 'all' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#27B199"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM7.07 18.28C7.5 17.38 10.12 16.5 12 16.5C13.88 16.5 16.5 17.38 16.93 18.28C15.57 19.36 13.86 20 12 20C10.14 20 8.43 19.36 7.07 18.28ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.5 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.5 18.36 16.83ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM12 11C11.6022 11 11.2206 10.842 10.9393 10.5607C10.658 10.2794 10.5 9.89782 10.5 9.5C10.5 9.10218 10.658 8.72064 10.9393 8.43934C11.2206 8.15804 11.6022 8 12 8C12.3978 8 12.7794 8.15804 13.0607 8.43934C13.342 8.72064 13.5 9.10218 13.5 9.5C13.5 9.89782 13.342 10.2794 13.0607 10.5607C12.7794 10.842 12.3978 11 12 11Z"
              fill="inherit"
            ></path>
          </svg>

          <div>All Conversation</div>
        </a>

        <!-- client away -->
        <a
          href="javascript:void(0);"
          @click="getPeople('clientAway')"
          :class="currentTab === 'clientAway' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#27B199"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M20 12V7H22V12H20ZM20 16V14H22V16H20ZM10 13C12.67 13 18 14.34 18 17V20H2V17C2 14.34 7.33 13 10 13ZM10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4ZM10 14.9C7.03 14.9 3.9 16.36 3.9 17V18.1H16.1V17C16.1 16.36 12.97 14.9 10 14.9ZM10 5.9C9.44305 5.9 8.9089 6.12125 8.51508 6.51508C8.12125 6.9089 7.9 7.44305 7.9 8C7.9 8.55695 8.12125 9.0911 8.51508 9.48492C8.9089 9.87875 9.44305 10.1 10 10.1C10.557 10.1 11.0911 9.87875 11.4849 9.48492C11.8788 9.0911 12.1 8.55695 12.1 8C12.1 7.44305 11.8788 6.9089 11.4849 6.51508C11.0911 6.12125 10.557 5.9 10 5.9Z"
              fill="inherit"
            ></path>
          </svg>
          Client Away
        </a>

        <!-- staff pending -->
        <a
          href="javascript:void(0);"
          @click="getPeople('staffPending')"
          :class="currentTab === 'staffPending' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#27B199"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M18 21L15 18L18 15V17H22V19H18V21ZM13 18C13 18.71 13.15 19.39 13.42 20H2V17C2 14.79 5.58 13 10 13C11 13 11.96 13.09 12.85 13.26C13.68 13.42 14.44 13.64 15.11 13.92C13.83 14.83 13 16.32 13 18ZM4 17V18H11C11 16.96 11.23 15.97 11.64 15.08L10 15C6.69 15 4 15.9 4 17ZM10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4ZM10 6C9.46957 6 8.96086 6.21071 8.58579 6.58579C8.21071 6.96086 8 7.46957 8 8C8 8.53043 8.21071 9.03914 8.58579 9.41421C8.96086 9.78929 9.46957 10 10 10C10.5304 10 11.0391 9.78929 11.4142 9.41421C11.7893 9.03914 12 8.53043 12 8C12 7.46957 11.7893 6.96086 11.4142 6.58579C11.0391 6.21071 10.5304 6 10 6Z"
              fill="inherit"
            ></path>
          </svg>
          Staff Pending
        </a>

        <!-- client pending -->
        <a
          href="javascript:void(0);"
          @click="getPeople('clientPending')"
          :class="currentTab === 'clientPending' ? 'active' : ''"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#27B199"
            xmlns="http://www.w3.org/2000/svg"
            class="svg-fill"
          >
            <path
              d="M21.1 12.5L22.5 13.91L15.97 20.5L12.5 17L13.9 15.59L15.97 17.67L21.1 12.5ZM11 4C12.0609 4 13.0783 4.42143 13.8284 5.17157C14.5786 5.92172 15 6.93913 15 8C15 9.06087 14.5786 10.0783 13.8284 10.8284C13.0783 11.5786 12.0609 12 11 12C9.93913 12 8.92172 11.5786 8.17157 10.8284C7.42143 10.0783 7 9.06087 7 8C7 6.93913 7.42143 5.92172 8.17157 5.17157C8.92172 4.42143 9.93913 4 11 4ZM11 6C10.4696 6 9.96086 6.21071 9.58579 6.58579C9.21071 6.96086 9 7.46957 9 8C9 8.53043 9.21071 9.03914 9.58579 9.41421C9.96086 9.78929 10.4696 10 11 10C11.5304 10 12.0391 9.78929 12.4142 9.41421C12.7893 9.03914 13 8.53043 13 8C13 7.46957 12.7893 6.96086 12.4142 6.58579C12.0391 6.21071 11.5304 6 11 6ZM11 13C11.68 13 12.5 13.09 13.41 13.26L11.74 14.93L11 14.9C8.03 14.9 4.9 16.36 4.9 17V18.1H11.1L13 20H3V17C3 14.34 8.33 13 11 13Z"
              fill="inherit"
            ></path>
          </svg>
          Client Pending
        </a>
      </div>
    </div>

    <div
      class="conversation-list-item"
      style="overflow: hidden auto; height: 78vh"
    >
      <!-- <div class="is-unstable-connection visible">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1.85962" height="14.8769" rx="0.929808" transform="matrix(0.716994 0.69708 -0.716994 0.69708 10.667 0)" fill="#555555"></rect> <rect width="1.85962" height="14.8769" rx="0.929808" transform="matrix(0.716994 -0.69708 0.716994 0.69708 0 1.62939)" fill="#555555"></rect>
        </svg> 
        <strong>Unstable connection detected.</strong> 
        <p>You may have a new conversation that not rendered yet, click here to refresh your conversation list</p> <button>Refresh</button>
      </div> -->

      <div class="box-people">
        <div
          class="people-item"
          v-for="(people, key) in peoples"
          :class="{
            active: people.id === activeDialog,
            unread: people.unread,
          }"
          :key="key"
          @click="getMessage(people.id)"
        >
          <div class="people-header" style="display: flex">
            <div class="people-name-box">
              <div class="people-name text-truncate">
                {{ people.name }}
              </div>
            </div>
            <div class="people-time">
              <img
                v-if="people.unread"
                src="img/bell-solid.svg"
                class="faa-ring animated"
              />
              <span v-else>{{ people.time }}</span>
            </div>
          </div>
          <div class="people-last-msg text-truncate">
            <span v-if="people.reply" class="icon-reply">
              <img src="img/reply.svg" />
            </span>
            {{ people.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    users: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      switchOn: true,
      activeDialog: false,
      currentTab: "all",
      peoples: [],
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * toggle status
     * --------------------------------------------------------------
     */
    toggleSwitch: function () {
      this.switchOn = !this.switchOn;
      let status = this.switchOn ? "Available" : "Not Available";
      this.$swal({
        title: "Agent Status : " + status,
        html: "Your status is now changed to " + status,
        icon: "success",
      });
    },

    /**
     * --------------------------------------------------------------
     * get detail percakapan
     * --------------------------------------------------------------
     */
    getMessage: function (id) {
      let dialog = _.find(this.users, ["id", id]);
      this.$emit("fromChildDialogSelected", {
        type: "dialogSelected",
        data: dialog,
      });
      this.activeDialog = id;
      // this.$swal("Halo",`id people ${id}`, "success");
      // console.log(dialog)
    },

    /**
     * --------------------------------------------------------------
     * for filter dialog
     * --------------------------------------------------------------
     */
    getPeople: function (tipe = "all") {
      this.currentTab = tipe;

      let people;
      switch (tipe) {
        // more than 5 minute
        case "clientAway":
          people = _.filter(this.users, (usr) => {
            return usr.reply && usr.clientAway;
          });
          break;

        // waiting response client
        case "clientPending":
          people = _.filter(this.users, (usr) => {
            return usr.reply && !usr.clientAway;
          });
          break;

        // staf not yet reply
        case "staffPending":
          people = _.filter(this.users, (usr) => {
            return usr.unread || !usr.reply;
          });
          break;

        // all
        default:
          people = this.users;
          break;
      }
      this.peoples = people;
    },

    logout: function () {
      this.$swal({
        title: "Are you sure?",
        html: "Anda harus keluar dari semua chat",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.$store.dispatch("staffLogout");
          //this.$router.push("/auth"); // issue page login jadi kecil
          location.href = "/auth";
        }
      });
    },
  },
  mounted() {
    this.peoples = this.users;
  },
};
</script>

<style scoped>
@import "../../assets/css/ringing.css";

.box-people {
  line-height: 1.2rem;
  display: flex;
  padding: 2%;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.box-people .people-item {
  justify-content: space-around;
  width: 48%;
  margin: 1%;
  box-sizing: border-box;
}

.people-item {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #23b098;
  border-radius: 0.25rem;
  padding: 3%;
}
.people-item:hover {
  background: #68c8b7;
  cursor: pointer;
  color: white;
}
.people-item:hover .people-name {
  color: white;
}

.people-name-box {
  flex-grow: 3;
}
.people-name {
  width: 75%;
  font-size: 14px;
  color: #555555;
  font-weight: 500;
}

.people-last-msg {
  font-size: 10px;
}

.icon-reply img {
  width: 10px;
}
.people-time {
  font-size: 10px;
}

/**
   * --------------------------------------
   * for active conversation
   */
.people-item.active {
  color: white;
  background: #23b098;
}
.people-item.active .people-name {
  color: white;
}
/**
   * end active 
   * --------------------------------------
   */

/*========= UNREAD & RINGING ==============*/
/*.people-unread-msg{
    width: 16px;
    position: absolute;
    margin-left: 34%;
  }*/
/*========= END UNREAD ==============*/
</style>
