<template>
  <div class="all-container">
    <div class="app-container">
      <div class="app-content">
        <!-- left panel -->
        <conversationList
          @fromChildDialogSelected="handleEmitFromChild"
          :users="forChildProps.users"
        ></conversationList>

        <!-- center panel -->
        <chatArea
          :dialogActive="forChildProps.dialogActive"
          :conversation="forChildProps.conversation"
          :writeMessage.sync="forChildProps.writeMessage"
          @toggleChatInfo="onChildToggleChatInfo"
          @sendMessage="handleEmitFromChild"
          @search="handleEmitFromChild"
        ></chatArea>

        <!-- right panel -->
        <userInfo
          :chatInfo="chatInfo"
          :dialogActive="forChildProps.dialogActive"
          @toggleChatInfo="onChildToggleChatInfo"
          @sendCanned="handleEmitFromChild"
        ></userInfo>
      </div>
    </div>
  </div>
</template>

<script>
// core
import conversationList from "@/components/staff/ConversationList.vue";
import chatArea from "@/components/staff/ChatArea.vue";
import userInfo from "@/components/staff/UserInfo.vue";

import users from "@/common/dummy/dialogs.json";
// import messages from "@/common/dummy/messages.json";
import $ from "jquery";
import { alert, waktu } from "@/common/mixins/mix_helper.js";
import { refCustomer, refMessages } from "@/common/firebase/init";

export default {
  mixins: [waktu, alert],
  components: {
    conversationList,
    chatArea,
    userInfo,
  },
  data() {
    return {
      forChildProps: {
        users: users,
        dialogActive: {},
        conversation: [],
        writeMessage: "",
      },
      masterMessages: [], // for filter
      chatInfo: false, // toggle user info
    };
  },
  methods: {
    onChildToggleChatInfo: function () {
      this.chatInfo = !this.chatInfo;
    },

    /**
     * --------------------------------------------------------------
     * for handle emit from child
     * @params {type:String, data: anything }
     * --------------------------------------------------------------
     */
    handleEmitFromChild: function (params) {
      switch (params.type) {
        case "dialogSelected":
          this.forChildProps.dialogActive = params.data;
          this.chatInfo = true;
          setTimeout(() => {
            this.scrollToBottomChat();
          }, 600);
          break;

        // canned message
        case "sendCanned":
          this.forChildProps.writeMessage = params.data.message;
          // this.sendMessage(params.data.message);
          break;

        // send message reguler
        case "sendMessage":
          this.sendMessage(params.data);
          break;

        // search keyword in conversation
        case "search":
          this.filterSearch(params.data);
          break;
      }

      console.warn("emit from child", params);
    },

    /**
     * --------------------------------------------------------------
     * send message reguler staff biasa
     * --------------------------------------------------------------
     */
    sendMessage: function (message) {
      this.forChildProps.writeMessage = "";
      this.$store.dispatch("staffSendMessage", {
        tipe: "text",
        message: message,
      });
    },

    /**
     * --------------------------------------------------------------
     * @params { reset : Bool | keyword : String }
     * --------------------------------------------------------------
     */
    filterSearch: function (data) {
      if (data.reset) {
        this.forChildProps.conversation = this.masterMessages;
      } else {
        let keyword = data.keyword.toLowerCase();
        this.forChildProps.conversation = this.masterMessages.filter((msg) => {
          let lower = msg.message.toLowerCase();
          return lower.includes(keyword);
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element staff, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToBottomChat: function () {
      let el = $(".qcw-comments.isReading");
      if (el.length) {
        el.scrollTop(parseInt(el[0].scrollHeight));
      }
    },
  },
  created() {
    refCustomer.on("value", (items) => {
      console.log("fb update1", items.val());
    });
    refCustomer.limitToLast(1).on("child_added", (items) => {
      console.warn("new customer", items.val());
    });

    /**
     * --------------------------------------------------------------
     * listen ref message
     * --------------------------------------------------------------
     */
    // init all message
    refMessages.once("value", (items) => {
      items.forEach((item) => {
        this.masterMessages.push(item.val());
      });
    });

    let storageName = "readMsg";
    let key = JSON.parse(localStorage.getItem(storageName));
    if (key == null) {
      localStorage.setItem(storageName, JSON.stringify([]));
      key = [];
    }

    // listen new message
    refMessages.on("child_added", (items) => {
      let item = items.val();
      if (!key.includes(items.key) && item.actor === "customer") {
        this.mixAlert(item.name, item.message);
        key.push(items.key);
        localStorage.setItem(storageName, JSON.stringify(key));
      }
      this.masterMessages.push(item);
      setTimeout(() => {
        this.scrollToBottomChat();
      }, 1000);
    });
  },
  mounted() {
    this.forChildProps.conversation = this.masterMessages;
  },
};
</script>

<style>
@import "../assets/qiscus/menu.css";
@import "../assets/qiscus/main.css";
@import "../assets/qiscus/qiscus-sdk.2.10.0.css";
@import "../assets/qiscus/inbox.css";
@import "../assets/qiscus/flex.css";
@import "../assets/qiscus/override.css";
@import "../assets/qiscus/simplemde.min.css";
@import "../assets/css/rw-custom.css";
.all-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
</style>
