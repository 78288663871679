<template>
  <div class="app-chat-area relative">
    <!-- for blank rooms -->
    <div class="blank-room" v-if="blankRoom">
      <img src="img/empty_room.svg" alt="blank" />
      <p>Please select a conversation to start messaging</p>
    </div>

    <!-- if staff select conversation -->
    <div
      v-else
      class="qcw-container qcw-container--wide"
      style="display: block"
    >
      <div class="qcw-chat-wrapper">
        <div class="qcw-main">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="qiscus-sdk-sprite"
          >
            <symbol id="ic-attachment" viewBox="0 0 39 48">
              <use xlink:href="#a1"></use>
              <defs>
                <path
                  id="a1"
                  d="M20 18.2l-9.2 14.5c-.8 1.1-.4 2.8.7 3.5 1.2.8 2.8.4 3.6-.7L24.3 21c.5-.8 1.5-1 2.3-.5.8.5 1 1.6.5 2.3L16.1 40a6 6 0 0 1-8.2 2A6 6 0 0 1 6 33.7L22.6 7.9A6 6 0 0 1 31 6a6 6 0 0 1 1.8 8.2c-.9 1.2-.5 2.8.8 3.6 1.1.7 2.7.3 3.5-.8A11.1 11.1 0 0 0 18.3 5L1.7 30.9a11.1 11.1 0 0 0 18.8 12l11.2-17.5a7 7 0 0 0-2.2-9.2 6.8 6.8 0 0 0-9.4 2z"
                ></path>
              </defs>
            </symbol>
            <symbol id="ic-close" viewBox="0 0 20 20">
              <path
                id="path0_fill"
                d="M 19.6022 19.6022C 20.1326 19.072 20.1326 18.212 19.6022 17.6819L 11.9204 9.99988L 19.6023 2.31818C 20.1326 1.78773 20.1326 0.928053 19.6023 0.397595C 19.072 -0.132532 18.2121 -0.132532 17.6818 0.397595L 10 8.07962L 2.31819 0.397595C 1.78788 -0.132532 0.928042 -0.132532 0.397732 0.397595C -0.132577 0.928053 -0.132577 1.78773 0.397732 2.31818L 8.07957 9.99988L 0.397753 17.6819C -0.132557 18.212 -0.132557 19.072 0.397753 19.6022C 0.928063 20.1326 1.78788 20.1326 2.31821 19.6022L 10 11.9205L 17.6818 19.6022C 18.2121 20.1326 19.0719 20.1326 19.6022 19.6022Z"
              ></path>
            </symbol>
            <symbol id="ic-docs-attachment-render-buble" viewBox="0 0 36 50">
              <g transform="translate(.5 .5)">
                <use xlink:href="#a3"></use>
                <use xlink:href="#b3"></use>
                <use xlink:href="#c3"></use>
                <use xlink:href="#d3"></use>
              </g>
              <defs>
                <path
                  id="a3"
                  d="M7 21.7h20.1a1.3 1.3 0 1 1 0 2.6h-20a1.3 1.3 0 1 1 0-2.6z"
                ></path>
                <path
                  id="b3"
                  d="M27.1 28.8h-20a1.3 1.3 0 1 0 0 2.7h20a1.3 1.3 0 1 0 0-2.7z"
                ></path>
                <path
                  id="c3"
                  d="M7 36h10.7a1.3 1.3 0 1 1 0 2.6H7.1a1.3 1.3 0 1 1 0-2.7z"
                ></path>
                <path
                  id="d3"
                  fill-rule="evenodd"
                  d="M5.3 5a5.5 5.5 0 0 1 11 0h14.6c2.5 0 4.5 2 4.5 4.5v28c0 .5-.2 1-.5 1.4l-9.6 9.6c-.4.3-.8.5-1.3.5H4.5C2 49 0 47 0 44.5v-35C0 7 2 5 4.5 5h.8zM9 5h3.5A1.8 1.8 0 0 0 9 5zM4.5 8.7c-.5 0-.8.4-.8.8v35c0 .5.3.8.8.8h17.7v-7.7c0-1 .8-1.9 1.8-1.9h7.8V9.5c0-.4-.4-.8-.9-.8H16.2v5.7a4.3 4.3 0 0 1-8.5 0V12a1.8 1.8 0 1 1 3.7 0v2.4c0 .3.3.6.6.6s.6-.3.6-.6V8.7H4.5zm21.3 30.7v3.3l3.4-3.3h-3.4z"
                ></path>
              </defs>
            </symbol>
            <symbol id="ic-docs-upload" viewBox="0 0 36 45">
              <g transform="translate(0 .5)">
                <use xlink:href="#a4"></use>
                <use xlink:href="#b4"></use>
                <use xlink:href="#c4"></use>
                <use xlink:href="#d4"></use>
              </g>
              <defs>
                <path
                  id="a4"
                  d="M26 8.5H10c-1.2 0-2 .7-2 1.5s.8 1.5 2 1.5h16c1 0 2-.7 2-1.5s-1-1.5-2-1.5z"
                ></path>
                <path
                  id="b4"
                  d="M10 15.5h16c1 0 2 .7 2 1.5s-1 1.5-2 1.5H10c-1.2 0-2-.7-2-1.5s.8-1.5 2-1.5z"
                ></path>
                <path
                  id="c4"
                  d="M9.3 22.5h11.2c.8 0 1.4.7 1.4 1.5s-.6 1.5-1.4 1.5H9.3c-.7 0-1.4-.7-1.4-1.5s.7-1.5 1.4-1.5z"
                ></path>
                <path
                  id="d4"
                  fill-rule="evenodd"
                  d="M11 0H4.4C2 0 0 2 0 4.5v35C0 42 2 44 4.5 44H24c.5 0 1-.2 1.3-.6l9.6-9.6c.3-.3.5-.8.5-1.3v-28C35.4 2 33.4 0 31 0H11zM3.6 4.5c0-.5.3-.8.8-.8h26.4c.5 0 .9.3.9.8v26.2H24c-1 0-1.8.8-1.8 1.8v7.8H4.5a.8.8 0 0 1-.8-.8v-35zm22.1 33.2v-3.3h3.4l-3.4 3.3z"
                ></path>
              </defs>
            </symbol>
            <symbol id="ic-envelope" viewBox="0 0 500 500">
              <path
                d="M354.2 312.5l-60.6-65.7 60.6-60.7a2 2 0 0 1 3.4 1.4v123.7a2 2 0 0 1-3.4 1.3zm-92.5-33.7a18.5 18.5 0 0 1-13.2 5.5h-.1c-5 0-9.8-2-13.4-5.6l-18-17.9-61.5 62.7a2 2 0 0 0 1.4 3.4h187a2 2 0 0 0 1.5-3.3l-61.6-67-22.1 22.2zm-13.3-8.5a5 5 0 0 0 3.5-1.4l91.7-91.9a2 2 0 0 0-1.4-3.4h-188a2 2 0 0 0-1.4 3.4l92 91.9a5 5 0 0 0 3.6 1.4zm-106-79.2V312a2 2 0 0 0 3.5 1.4l61.3-62.4-61.3-61.3a2 2 0 0 0-3.4 1.5z"
              ></path>
            </symbol>
            <symbol id="ic-trash" viewBox="0 0 39 48">
              <use xlink:href="#a8"></use>
              <use xlink:href="#b8"></use>
              <use xlink:href="#c8"></use>
              <use xlink:href="#d"></use>
              <defs>
                <path
                  id="a8"
                  d="M18 36.6V25.4c0-.8.7-1.4 1.5-1.4s1.5.6 1.5 1.4v11.2c0 .8-.7 1.4-1.5 1.4s-1.5-.6-1.5-1.4z"
                ></path>
                <path
                  id="b8"
                  d="M24 25.4v11.2c0 .8.7 1.4 1.5 1.4s1.5-.6 1.5-1.4V25.4c0-.8-.7-1.4-1.5-1.4s-1.5.6-1.5 1.4z"
                ></path>
                <path
                  id="c8"
                  d="M12 36.6V25.4c0-.8.7-1.4 1.5-1.4s1.5.6 1.5 1.4v11.2c0 .8-.7 1.4-1.5 1.4s-1.5-.6-1.5-1.4z"
                ></path>
                <path
                  id="d"
                  fill-rule="evenodd"
                  d="M28.2 6h7.4C37.5 6 39 7.8 39 9.6V16c0 1.8-1.5 3.4-3.4 3.4h-.2v23.9c0 2.6-2.1 4.7-4.6 4.7H8.2a4.7 4.7 0 0 1-4.6-4.7V19.4h-.2A3.5 3.5 0 0 1 0 16V9.5c0-1.8 1.5-3.4 3.4-3.4h7.4V3.5c0-2 1.5-3.5 3.4-3.5h10.6c1.9 0 3.4 1.6 3.4 3.5V6zM24 4.5h-8.8v1.7H24V4.4zm10.7 6H4.3V15h30.3v-4.5zm-3.8 33.2c.2 0 .3-.1.3-.3v-24H7.9v23.9c0 .2.1.3.3.3h22.6z"
                ></path>
              </defs>
            </symbol>
            <symbol id="ic-chevron-down" viewBox="0 0 1792 1792">
              <path
                d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"
              ></path>
            </symbol>
            <symbol id="ic-chevron-left" viewBox="0 0 1792 1792">
              <path
                d="M1427 301L896 832l531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19L429 877q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
              ></path>
            </symbol>
            <symbol id="ic-chevron-right" viewBox="0 0 1792 1792">
              <path
                d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
              ></path>
            </symbol>
            <symbol id="ic-image" viewBox="0 0 512 512">
              <path d="M368 224a48 48 0 1 0 0-96 48 48 0 0 0 0 96z"></path>
              <path
                d="M452 64H60a28 28 0 0 0-28 28.3v327.4A28 28 0 0 0 60 448h392a28 28 0 0 0 28-28.3V92.3A28 28 0 0 0 452 64zM348.9 261.7c-3-3.5-7.6-6.2-12.8-6.2-5.1 0-8.7 2.4-12.8 5.7L304.6 277c-3.9 2.8-7 4.7-11.5 4.7-4.3 0-8.2-1.6-11-4.1l-4.3-4.1-53.8-58.2a22 22 0 0 0-33.5.3L64 368.2V107.7c1-6.8 6.3-11.7 13.1-11.7h357.7c6.9 0 12.5 5.1 12.9 12l.3 260.4-99.1-106.7z"
              ></path>
            </symbol>
            <symbol id="ic-chat-buble" viewBox="0 0 24 24">
              <path
                d="M2 2a1 1 0 0 0-1 1v13c0 .5.4 1 1 1h2l2.8 3.3c.4.4 1 .4 1.3 0L11 17h11c.6 0 1-.4 1-1V3c0-.5-.4-1-1-1H2zm3.2 4l-.2.2v.6l.2.2h12.6l.2-.2v-.6l-.2-.2H5.2zm0 3l-.2.2v.6l.2.2h12.6l.2-.2v-.6l-.2-.2H5.2zm0 3l-.2.2v.6c0 .1 0 .2.2.2h8.6l.2-.2v-.6c0-.1 0-.2-.2-.2H5.2z"
                fill-rule="evenodd"
              ></path>
            </symbol>
            <symbol id="ic-clock" viewBox="0 0 24 24">
              <path
                d="M16.3 21l2 2 .8-.7-2-2a9 9 0 1 0-10.3 0l-2 2 .7.7 2.1-2.1a9 9 0 0 0 8.7 0zM12 21a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-8V7h1v7H8v-1h4zM6.9 3.7a1 1 0 0 0-1.4-.2L3.9 4.7a1 1 0 0 0-.2 1.4l.5.6-.6.9-.7-.9A2 2 0 0 1 3.3 4l1.6-1.2a2 2 0 0 1 2.8.3l.5.7-1 .5-.3-.5zM20.3 6c.3-.4.2-1-.2-1.4l-1.6-1.2a1 1 0 0 0-1.4.2l-.4.5a10 10 0 0 0-1-.5l.6-.7c.7-.8 2-1 2.8-.3l1.6 1.2c.9.7 1 2 .4 2.8l-.7.9a10 10 0 0 0-.6-.9l.5-.6z"
                fill-rule="evenodd"
              ></path>
            </symbol>
            <symbol id="ic-link-out" viewBox="0 0 20 20">
              <path
                id="path0_fill"
                d="M 2.177 5.3125C 2.177 3.64404 3.46191 2.29175 5.04688 2.29175L 6.52075 2.29175C 7.12207 2.29175 7.60938 1.77856 7.60938 1.14575C 7.60938 0.512939 7.12207 0 6.52075 0L 5.04688 0C 2.25952 0 0 2.37842 0 5.3125L 0 14.6875C 0 17.6216 2.25952 20 5.04688 20L 14.9531 20C 17.7405 20 20 17.6216 20 14.6875L 20 14.0833C 20 13.4504 19.5127 12.9375 18.9114 12.9375C 18.3103 12.9375 17.823 13.4504 17.823 14.0833L 17.823 14.6875C 17.823 16.356 16.5381 17.7083 14.9531 17.7083L 5.04688 17.7083C 3.46191 17.7083 2.177 16.356 2.177 14.6875L 2.177 5.3125Z"
              ></path>
              <path
                id="path1_fill"
                d="M 11.9844 0C 11.3833 0 10.8958 0.512939 10.8958 1.14575C 10.8958 1.77856 11.3833 2.29175 11.9844 2.29175L 16.437 2.29175L 10.2021 9.45142C 9.79582 9.91797 9.82585 10.6428 10.269 11.0703C 10.7121 11.498 11.4006 11.4663 11.8068 11L 17.823 4.09155L 17.823 8.4375C 17.823 9.07031 18.3103 9.58325 18.9114 9.58325C 19.5127 9.58325 20 9.07031 20 8.4375L 20 1.14575C 20 0.512939 19.5127 0 18.9114 0L 11.9844 0Z"
              ></path>
            </symbol>
            <symbol id="ic-phone" viewBox="0 0 18 18">
              <path
                d="M3.6 7.8c1.4 2.8 3.8 5.1 6.6 6.6l2.2-2.2c.3-.3.7-.4 1-.2 1.1.4 2.3.6 3.6.6.6 0 1 .4 1 1V17c0 .6-.4 1-1 1A17 17 0 0 1 0 1c0-.6.4-1 1-1h3.5c.6 0 1 .4 1 1 0 1.2.2 2.4.6 3.6.1.3 0 .7-.2 1L3.6 7.8z"
                fill-rule="evenodd"
              ></path>
            </symbol>
            <symbol id="ic-user" viewBox="0 0 1792 1792">
              <path
                d="M1600 1405q0 120-73 189.5t-194 69.5H459q-121 0-194-69.5T192 1405q0-53 3.5-103.5t14-109T236 1084t43-97.5 62-81 85.5-53.5T538 832q9 0 42 21.5t74.5 48 108 48T896 971t133.5-21.5 108-48 74.5-48 42-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5 103.5zm-320-893q0 159-112.5 271.5T896 896 624.5 783.5 512 512t112.5-271.5T896 128t271.5 112.5T1280 512z"
              ></path>
            </symbol>
            <symbol id="ic-smiley" viewBox="0 0 24 24">
              <path
                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.507 13.941c-1.512 1.195-3.174 1.931-5.506 1.931-2.334 0-3.996-.736-5.508-1.931l-.493.493c1.127 1.72 3.2 3.566 6.001 3.566 2.8 0 4.872-1.846 5.999-3.566l-.493-.493zm-9.007-5.941c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z"
              ></path>
            </symbol>
            <symbol id="ic-image-attachment" viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M 9 9C 10.6567 9 12 7.65686 12 6C 12 4.34314 10.6567 3 9 3C 7.34326 3 6 4.34314 6 6C 6 7.65686 7.34326 9 9 9ZM 11 6C 11 7.10455 10.1045 8 9 8C 7.89551 8 7 7.10455 7 6C 7 4.89545 7.89551 4 9 4C 10.1045 4 11 4.89545 11 6Z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M 2.49414 0C 1.1167 0 0 1.24371 0 2.77789L 0 17.2221C 0 18.7562 1.1167 20 2.49414 20L 17.5059 20C 18.8835 20 20 18.7562 20 17.2221L 20 2.67212C 20 1.19635 18.926 0 17.6011 0L 2.49414 0ZM 2.49414 1.11115C 1.66772 1.11115 0.997559 1.85742 0.997559 2.77789L 0.997559 13.8889L 3.42456 11.7316C 4.3252 10.9309 5.66895 10.8868 6.62036 11.6267L 8.41357 13.0214C 9.0105 13.4858 9.86011 13.4329 10.395 12.898L 14.2324 9.06067C 15.2087 8.08435 16.7915 8.08435 17.7678 9.06067L 19.0024 10.2952L 19.0024 2.67212C 19.0024 1.81 18.375 1.11115 17.6011 1.11115L 2.49414 1.11115ZM 0.997559 17.2221L 0.997559 15.2268L 4.08887 12.4789C 4.62939 11.9986 5.43555 11.9721 6.00635 12.4161L 7.79956 13.8107C 8.79468 14.5848 10.2107 14.4966 11.1021 13.6051L 14.9395 9.76776C 15.5254 9.18201 16.4751 9.18201 17.0608 9.76776L 19.0024 11.7095L 19.0024 17.2221C 19.0024 18.1426 18.3325 18.8889 17.5059 18.8889L 2.49414 18.8889C 1.66772 18.8889 0.997559 18.1426 0.997559 17.2221Z"
              ></path>
            </symbol>
            <symbol id="ic-send-message" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                d="M 0.0115851 2.04123C -0.144872 0.603643 1.31315 -0.445178 2.58651 0.188973L 22.9904 10.3504C 24.3365 11.0208 24.3365 12.9792 22.9904 13.6496L 2.58651 23.811C 1.31315 24.4452 -0.144872 23.3964 0.011585 21.9588L 0.865213 14.1153C 0.95705 13.2715 1.60379 12.6021 2.42898 12.4968L 6.32188 12L 2.42898 11.5032C 1.60379 11.3979 0.95705 10.7285 0.865213 9.88468L 0.0115851 2.04123ZM 2.06106 1.28869C 1.63661 1.07731 1.1506 1.42691 1.20275 1.90611L 2.05638 9.74956C 2.08699 10.0308 2.30257 10.254 2.57764 10.2891L 6.47054 10.7859C 7.87033 10.9645 7.87033 13.0355 6.47054 13.2141L 2.57764 13.7109C 2.30257 13.746 2.08699 13.9692 2.05638 14.2504L 1.20275 22.0939C 1.1506 22.5731 1.63661 22.9227 2.06106 22.7113L 22.465 12.5499C 22.9137 12.3264 22.9137 11.6736 22.465 11.4501L 2.06106 1.28869Z"
              ></path>
            </symbol>
            <symbol id="ic-minimize" viewBox="0 0 21 2">
              <path
                id="path0_stroke"
                d="M 0 -1C -0.552285 -1 -1 -0.552285 -1 0C -1 0.552285 -0.552285 1 0 1L 0 -1ZM 18 1C 18.5523 1 19 0.552285 19 0C 19 -0.552285 18.5523 -1 18 -1L 18 1ZM 0 1L 18 1L 18 -1L 0 -1L 0 1Z"
              ></path>
            </symbol>
            <symbol id="ic-load" viewBox="0 0 10 10">
              <path
                fill-rule="evenodd"
                d="M 5 1C 2.79086 1 1 2.79086 1 5C 1 7.20914 2.79086 9 5 9C 7.20914 9 9 7.20914 9 5C 9 4.72386 9.22386 4.5 9.5 4.5C 9.77614 4.5 10 4.72386 10 5C 10 7.76142 7.76142 10 5 10C 2.23858 10 0 7.76142 0 5C 0 2.23858 2.23858 0 5 0C 5.27614 0 5.5 0.223858 5.5 0.5C 5.5 0.776142 5.27614 1 5 1Z"
              ></path>
            </symbol>
            <symbol id="ic-check" viewBox="0 0 12 9">
              <path
                fill-rule="evenodd"
                d="M 11.7985 0.111549C 12.0323 0.285046 12.0678 0.598796 11.8779 0.812329L 4.37336 8.84207C 4.17187 9.05766 3.83437 9.05172 3.6402 8.82917L 0.134965 4.8116C -0.0634062 4.60454 -0.0404331 4.28982 0.186277 4.10864C 0.412988 3.92746 0.757585 3.94845 0.955956 4.1555L 4 7.7013L 11.0312 0.184044C 11.2212 -0.0294899 11.5647 -0.0619468 11.7985 0.111549Z"
              ></path>
            </symbol>
            <symbol id="ic-double-check" viewBox="0 0 16 9">
              <path
                d="M 6.77422 6.27328L 6.08575 5.47144L 5.37432 6.23204L 6.06866 7.02799L 6.77422 6.27328Z"
              ></path>
              <path
                d="M 5.37432 7.77099L 4.37334 8.84207C 4.17193 9.05767 3.83453 9.05172 3.64019 8.82916L 0.135063 4.81161C -0.0634234 4.60454 -0.0404742 4.2898 0.186332 4.10861C 0.413139 3.92748 0.757621 3.94841 0.956108 4.15549L 4.00005 7.70129L 4.67974 6.97472L 5.37432 7.77099Z"
              ></path>
              <path
                d="M 6.78081 4.72832L 7.46905 5.52985L 11.878 0.812304C 12.0679 0.598776 12.0323 0.285033 11.7986 0.111553C 11.5648 -0.0619265 11.2212 -0.0295244 11.0313 0.184066L 6.78081 4.72832Z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M 15.7985 0.111549C 16.0323 0.285046 16.0678 0.598796 15.8779 0.812329L 8.37336 8.84207C 8.17187 9.05766 7.83437 9.05172 7.6402 8.82917L 4.13497 4.8116C 3.93659 4.60454 3.95957 4.28982 4.18628 4.10864C 4.41299 3.92746 4.75759 3.94845 4.95596 4.1555L 8 7.7013L 15.0312 0.184044C 15.2212 -0.0294899 15.5647 -0.0619468 15.7985 0.111549Z"
              ></path>
            </symbol>
            <symbol id="ic-reply" viewBox="0 0 13 16">
              <path
                id="path0_fill"
                d="M 4.00572 0.248674C 4.33264 -0.0828914 4.86267 -0.0828914 5.18959 0.248674C 5.5165 0.58024 5.5165 1.11781 5.18959 1.44938L 2.85812 3.814L 4.2841 3.814C 9.09776 3.814 13 7.77173 13 12.6538L 13 15.151C 13 15.6199 12.6252 16 12.1629 16C 11.7006 16 11.3258 15.6199 11.3258 15.151L 11.3258 12.6538C 11.3258 8.70954 8.17311 5.51205 4.2841 5.51205L 2.85812 5.51205L 5.18959 7.87667C 5.5165 8.20823 5.5165 8.7458 5.18959 9.07737C 4.86267 9.40893 4.33264 9.40893 4.00572 9.07737L 0.245183 5.26337C -0.0817275 4.93181 -0.0817275 4.39423 0.245183 4.06267L 4.00572 0.248674Z"
              ></path>
            </symbol>
            <symbol id="ic-quote" viewBox="0 0 11 13">
              <path
                id="path0_fill"
                fill-rule="evenodd"
                d="M 3.42639 5.04879C 3.20799 5.13423 2.97257 5.18074 2.72708 5.18074L 2.04983 5.18074C 0.917708 5.18074 0 4.19161 0 2.97145L 0 2.20928C 0 0.989122 0.917708 0 2.04983 0L 2.72708 0C 3.85903 0 4.77674 0.989122 4.77674 2.20928L 4.77674 3.73138C 4.77674 4.58025 2.34566 11.7371 1.95156 12.6038C 1.69479 13.1685 0.902257 13.1685 0.902257 12.2253C 0.902257 11.8023 3.28785 5.36084 3.42639 5.04879Z"
              ></path>
              <path
                id="path1_fill"
                fill-rule="evenodd"
                d="M 9.64965 5.04879C 9.43125 5.13423 9.19583 5.18074 8.95035 5.18074L 8.27309 5.18074C 7.14097 5.18074 6.22326 4.19161 6.22326 2.97145L 6.22326 2.20928C 6.22326 0.989122 7.14097 0 8.27309 0L 8.95035 0C 10.0823 0 11 0.989122 11 2.20928L 11 3.73138C 11 4.58025 8.63333 11.6176 8.17483 12.6038C 7.9 13.1949 7.16667 13.1685 7.12552 12.2253C 7.10698 11.8003 9.51111 5.36084 9.64965 5.04879Z"
              ></path>
            </symbol>
            <symbol id="ic-more-horiz" viewBox="0 0 11 13">
              <g id="Canvas" fill-rule="evenodd">
                <g id="dot">
                  <g id="Ellipse">
                    <circle cx="1" cy="1" r="1" fill-rule="evenodd"></circle>
                  </g>
                  <g id="Ellipse_2">
                    <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(5 0)"
                      fill-rule="evenodd"
                    ></circle>
                  </g>
                  <g id="Ellipse_3">
                    <circle
                      cx="1"
                      cy="1"
                      r="1"
                      transform="translate(10 0)"
                      fill-rule="evenodd"
                    ></circle>
                  </g>
                </g>
              </g>
            </symbol>
          </svg>

          <!-- header title -->
          <div class="qcw-header" style="background: rgb(255, 255, 255)">
            <div class="qcw-header-avatar">
              <img src="img/rw/customer.png" />
            </div>
            <div class="qcw-header-info">
              <div>
                <div
                  class="qcw-user-display-name"
                  style="color: rgb(102, 102, 102)"
                >
                  {{ dialogActive.name }}
                </div>
                <div
                  class="qcw-user-status qcw-user-status--group qismo-user-status--override"
                >
                  {{ dialogActive.email }}
                </div>
              </div>
            </div>
            <i class="qcw-window-toggle-btn">
              <svg class="qc-icon" style="fill: rgb(151, 151, 151)">
                <use xlink:href="#ic-minimize"></use>
              </svg>
            </i>
            <div class="qcw-header__actions">
              <a class="link bot bot-header" href="javascript:void(0)"
                >ChatBot</a
              ><a class="link bot-header" href="javascript:void(0)"
                ><span
                  class="switch switch-on"
                  onclick="toggleSwitch(this)"
                ></span></a
              ><a
                class="link menu"
                href="javascript:void(0)"
                @click="toggleChatSearch()"
                ><i class="material-icons">manage_search</i>
              </a>
              <a
                class="link menu"
                href="javascript:void(0)"
                @click="toggleChatInfo()"
                ><i class="material-icons">info</i></a
              >
            </div>
          </div>

          <!-- search form field -->
          <div class="qcw-header search" v-if="searchOpen">
            <div class="search-form">
              <input
                v-model="form.search"
                @keyup.enter="search()"
                type="text"
                placeholder="Search by keyword ..."
              />
              <div class="clear-search">
                <i class="material-icons" @click="toggleChatSearch()">close</i>
              </div>
            </div>
          </div>

          <!-- message list  -->
          <div class="qcw-comments isReading">
            <div class="inner">
              <ul>
                <!-- looping staff -->
                <template v-for="(message, key) in conversation">
                  <li
                    v-if="message.actor === 'system'"
                    :class="
                      message.tipe === 'reached'
                        ? 'qcw-load-more qcw-top-page'
                        : ''
                    "
                    :key="key"
                  >
                    <!-- has reached page -->
                    <span v-if="message.tipe === 'reached'"
                      >You've reached first page</span
                    >

                    <!-- system notif -->
                    <div
                      v-if="message.tipe === 'resolved'"
                      class="parent--container qcw-group"
                    >
                      <div
                        class="qcw-comment-container qcw-comment--system_event qcw-comment--cust"
                      >
                        <div class="qcw-comment--system-event">
                          <div>
                            <div>
                              {{ mixEpochToHours(message.time) }} -
                              {{ message.message }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <!-- for reguler -->
                  <li v-if="message.actor !== 'system'" :key="key">
                    <!-- for customer -->
                    <div
                      v-if="message.actor === 'customer'"
                      class="parent--container qcw-group"
                      :class="message.date ? 'contain-date' : ''"
                    >
                      <div
                        id=""
                        class="qcw-comment-container qcw-comment--text qcw-comment--cust"
                      >
                        <!-- if contain date -->
                        <div class="qcw-comment-date" v-if="message.date">
                          <div>{{ message.date }}</div>
                        </div>

                        <div class="qcw-comment comment--parent comment--last">
                          <div class="qcw-avatar">
                            <img src="img/rw/customer.png" />
                          </div>
                          <div
                            class="qcw-comment__message hover-effect"
                            style="
                              color: rgb(102, 102, 102);
                              background: rgb(232, 232, 232);
                            "
                          >
                            <div
                              class="tip"
                              style="
                                border-left-color: transparent;
                                border-right-color: rgb(232, 232, 232);
                              "
                            ></div>
                            <span class="qcw-comment__username">{{
                              message.name
                            }}</span>
                            <i class="reply-btn">
                              <svg class="qc-icon">
                                <use xlink:href="#ic-reply"></use>
                              </svg>
                            </i>

                            <div class="comment-text">
                              <div>
                                <div
                                  class="qcw-comment__content"
                                  v-html="message.message"
                                >
                                  <!-- message here -->
                                </div>
                              </div>
                            </div>
                            <span
                              class="qcw-comment__time"
                              style="color: rgb(151, 151, 151)"
                              >{{ mixEpochToHours(message.time) }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- for staff -->
                    <div
                      v-if="message.actor === 'staff'"
                      class="parent--container my--container qcw-group"
                      :class="message.date ? 'contain-date' : ''"
                    >
                      <div
                        class="qcw-comment-container qcw-comment--text comment--me"
                      >
                        <!-- if contain date -->
                        <div class="qcw-comment-date" v-if="message.date">
                          <div>{{ message.date }}</div>
                        </div>

                        <div
                          class="qcw-comment comment--me comment--parent comment--last"
                        >
                          <div class="qcw-avatar">
                            <img src="/img/rw/staff.png" />
                          </div>
                          <div
                            class="qcw-comment__message hover-effect"
                            style="background: rgb(255, 255, 255)"
                          >
                            <div
                              class="tip"
                              style="
                                border-left-color: rgb(255, 255, 255);
                                border-right-color: transparent;
                              "
                            ></div>
                            <span
                              class="qcw-comment__username qcw-comment__username--hide"
                              >Personal</span
                            >
                            <i class="qcw-comment__more" @click="alert('sd')">
                              <svg class="qc-icon">
                                <use xlink:href="#ic-more-horiz"></use>
                              </svg>
                            </i>
                            <!-- <div class="qcw-comment__more--menu">
                              <ul>
                                <li>Reply</li>
                                <li>Delete</li>
                                <li>Message Info</li>
                              </ul>
                            </div> -->

                            <div class="comment-text">
                              <div>
                                <div
                                  class="qcw-comment__content"
                                  v-html="message.message"
                                >
                                  <!-- this message html -->
                                </div>
                              </div>
                            </div>
                            <span class="qcw-comment__time">{{
                              mixEpochToHours(message.time)
                            }}</span>
                            <div>
                              <div
                                class="qcw-comment__state qcw-comment__state--read"
                              >
                                <svg class="qc-icon ic-double-check__state">
                                  <use xlink:href="#ic-double-check"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <!-- form balasan -->
          <div class="qcw-comment-form">
            <!--            <i class="qcw-icon">
              <label>
                <input name="file_all" type="file" class="uploader__input" />
                <svg class="qc-icon" style="fill: rgb(151, 151, 151)">
                  <use xlink:href="#ic-smiley"></use>
                </svg>
              </label>
            </i>-->
            <i class="qcw-icon">
              <label>
                <input
                  type="file"
                  accept="image/*"
                  class="uploader__input"
                  @change="onChooseFile"
                />
                <svg class="qc-icon" style="fill: rgb(151, 151, 151)">
                  <use xlink:href="#ic-image-attachment"></use>
                </svg>
              </label>
            </i>
            <textarea
              @keyup.enter="onKeyUpMessage"
              :value="writeMessage"
              @input="$emit('update:writeMessage', $event.target.value)"
              placeholder="Send a message ..."
              style="color: rgb(102, 102, 102)"
            ></textarea>
            <i @click="sendMessage()">
              <svg
                class="qc-icon"
                :style="
                  writeMessage === ''
                    ? 'fill: rgb(151, 151, 151)'
                    : 'fill: rgb(148, 202, 98)'
                "
              >
                <use xlink:href="#ic-send-message"></use>
              </svg>
            </i>

            <div class="qcw-upload-toast"><span></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alert, waktu } from "@/common/mixins/mix_helper.js";
export default {
  mixins: [alert, waktu],
  props: {
    dialogActive: {
      required: true,
      type: Object,
    },
    conversation: {
      required: true,
      type: Array,
    },
    writeMessage: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      searchOpen: false,
      form: {
        search: "",
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * user info
     * --------------------------------------------------------------
     */
    toggleChatInfo: function () {
      this.$emit("toggleChatInfo", {});
    },

    /**
     * --------------------------------------------------------------
     * search keyword in staff | for toggle open search staff
     * --------------------------------------------------------------
     */
    toggleChatSearch: function () {
      this.searchOpen = !this.searchOpen;
      this.form.search = "";

      // for reset form & bring back all message
      this.$emit("search", {
        type: "search",
        data: {
          reset: true,
        },
      });
    },

    /**
     * --------------------------------------------------------------
     * on enter keypress
     * --------------------------------------------------------------
     */
    onKeyUpMessage: function () {
      this.sendMessage();
    },

    /**
     * --------------------------------------------------------------
     * trigger button send message
     * --------------------------------------------------------------
     */
    sendMessage: function () {
      let message = this.writeMessage.trim();
      if (message === "") {
        this.$swal("Error", "Message can't be empty", "error");
        this.$emit("update:writeMessage", "");
      } else {
        this.$emit("sendMessage", {
          type: "sendMessage",
          data: message,
        });
      }
    },

    /**
     * --------------------------------------------------------------
     * search keyword data in one conversation | send to parent
     * --------------------------------------------------------------
     */
    search: function () {
      this.$emit("search", {
        type: "search",
        data: {
          reset: false,
          keyword: this.form.search,
        },
      });
    },

    /**
     * --------------------------------------------------------------
     * upload image gan
     * --------------------------------------------------------------
     */
    onChooseFile: function (e) {
      let image = e.target.files[0];
      let allowedExt = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/bmp",
      ];

      if (allowedExt.includes(image.type)) {
        let size = Math.round(image.size / 1024);
        if (size > 2048) {
          this.$swal({
            title: "Error",
            html: `Ukuran gambar maksimal 2MB!`,
            icon: "error",
          });
        } else {
          let msg = URL.createObjectURL(image);
          msg = `<a href="${msg}" target="_blank"><img src="${msg}" class="img-fluid" style="width:100%"></a>`;
          this.$emit("sendMessage", { type: "sendMessage", data: msg });
        }
      } else {
        // alert when fail
        let ext = image.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `.<b>${
            ext[ext.length - 1]
          }</b> extensions are not allowed! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP </b></small>`,
          icon: "error",
        });
      }
    },
  },
  computed: {
    blankRoom: function () {
      return Object.keys(this.dialogActive).length === 0;
    },
  },
  mounted() {
    if (this.writeMessage !== "") {
      this.form.message = this.writeMessage;
    }
    console.warn(this.writeMessage);
  },
};
</script>
